// Need to have one css stylesheet imported here to make shakapacker happy
// All other stylesheets should be imported on the Vue apps themselves
import 'floating-vue/dist/style.css';

// Imports the javascript files in `app/components`
import "../src/components.js"

import "@hotwired/turbo-rails"
// Turn off turbo-drive because it causes pages to not load properly. Specifically, the clients page (there are others too)
// https://github.com/hotwired/turbo-rails
Turbo.session.drive = false;
